<div>
    <p>Kommune:</p>
    <li>
        <ul *ngFor="let kommune of kommuneList; let i = index"> 
            <input type="checkbox" id="{{kommune}}" name="{{kommune}}" value="{{i}}">{{kommune}}
        </ul>
    </li>
</div>
<div>
    <p>Number of places in kommune <input type="number" id="numberOfPlaces"></p>
</div>

<input type='button' value='Find Place' (click)='listPlaces()'>

<li *ngIf="showList">
    <ul *ngFor="let foo of result">{{foo}}</ul>
</li>
