import { Component, OnInit } from '@angular/core';
import places from '../../assets/places.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public placeList: any = places;
  public kommuneList = [];
  public result = [];
  public selectedKommune = [];
  public showList = false;

  constructor() { }

  // tslint:disable-next-line: typedef
  public listPlaces() {
    this.showList = true;
    this.selectedKommune = [];
    this.result = [];
    // tslint:disable-next-line: radix
    const numberOfPlaces: number = parseInt((document.getElementById('numberOfPlaces') as HTMLInputElement).value);

    // tslint:disable-next-line: forin
    for (let kommune of this.kommuneList) {
      this.validate(kommune);
    }

    if (!numberOfPlaces) {
      alert('Input a number first!');
      return;
    }

    console.log(this.selectedKommune);
    console.log(numberOfPlaces);

    for (let i = 0; i < numberOfPlaces; i++) {
      this.selectedKommune.forEach(element => {
        const randomNumber: number = Math.round(Math.random() * (this.placeList.places.kommune[element.name].length - 1));
        this.result.push(`Destination: ${this.placeList.places.kommune[element.name][randomNumber]}, Kommune: ${element.name}`);
      });
    }
  }

  // tslint:disable-next-line: typedef
  public validate(kommune) {
    if ((document.getElementById(kommune) as HTMLInputElement).checked) {
      this.selectedKommune.push({ name: kommune, index: (document.getElementById(kommune) as HTMLInputElement).value });
    }
  }

  ngOnInit(): void {
    // tslint:disable-next-line: forin
    for (const foo in this.placeList.places.kommune) {
      this.kommuneList.push(foo);
    }
    // console.log(this.placeList.places);
  }

}
